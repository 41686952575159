@import '../../styles/mixins.scss';

form#update-password {
  .title {
    @include label;
  }
}

.modal form#update-password {
  .title {
    display: none;
  }
}
