@import './mixins.scss';

.document {
  color: #000;
  padding: 15px;
  // font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

  &,
  * {
    color: #000;
  }

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }

  h3 {
    text-align: center;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .masthead {
    text-align: center;

    .firm-logo {
      margin-bottom: 30px;
    }
  }

  .table {
  }

  .signatures {
    display: block;
  }

  ul.parties {
    padding-left: 0;
    list-style: none;
  }

  .signature-row {
    max-width: 344px;

    h6 {
      font-size: 14px;
      margin-right: auto;
    }

    .label {
      margin-top: 10px;
      padding: 5px 15px;
    }

    // Text signatures
    .bitsy-form-group {
      margin-right: auto;
      margin-top: 8px;
      max-width: 300px;
      width: 100%;
    }

    .date {
      margin-right: auto;

      .value {
        padding: 5px 15px;
        max-width: 300px;
        width: 100%;
      }
    }

    // .signature {
    //   display: flex;
    //   flex-wrap: wrap;

    //   canvas, img, .blank-signature {
    //     max-width: 300px;
    //     min-height: 150px;
    //     border-bottom: 1px solid #010101;
    //   }

    //   .blank-signature {
    //     min-width: 300px;
    //   }

    //   canvas {
    //     box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);
    //   }

    //   .signature-buttons {
    //     margin-left: 6px;
    //     display: flex;
    //     justify-content: center;
    //     flex-direction: column;
    //   }

    //   .label {
    //     flex-basis: 100%;
    //     padding: 5px 15px;
    //   }
    // }
  }
}

button#iac-submit {
  @include button-primary;
  display: block;
  margin: 0 auto;
}
