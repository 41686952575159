@import './_variables.scss';

@mixin badge($backgroundColor: red, $content: '', $size: 16px) {
  content: $content;
  position: absolute;
  top: calc(#{$size} / -2);
  right: calc(#{$size} / -2);
  background: $backgroundColor;
  border-radius: 50%;
  line-height: 1;
  font-weight: normal;
  font-size: calc(#{$size} - 3px);
  width: $size;
  height: $size;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  svg {
    fill: #fff;
  }
}

@mixin button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 10px 14px;

  &:hover {
    color: #212529;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.dropdown-toggle {
    padding: 0;
    border: 0;
    margin-left: 16px;
  }

  &.primary {
    @include button-primary;
  }

  &.sm-button-accent {
    @include button-accent;
  }

  &.custom-color {
    @include button-accent;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
  }
}

@mixin button-accent {
  color: #ffffff;
  background: $color-accent;

  &:hover {
    color: #ffffff;
    background: rgb(249, 99, 177);
    transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
  }
}

@mixin button-primary {
  border-radius: 4px;
  background: $color-primary;
  color: #ffffff !important;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background: #09ab9f;
  }
}

@mixin button-warning {
  background: $color-warning;

  &:hover {
    background: $color-warning;
    opacity: 0.7;
    transition: 0.3s all;
  }
}

@mixin card-box-shadow($color: #ededed) {
  box-shadow: 0px 16px 24px $color;
}

@mixin doc-submit-success {
  text-align: center;

  .icon-container {
    background: $color-primary;
    display: inline-flex;
    flex-direction: column;
    padding: 30px;
    margin: 20px auto;
    border-radius: 50%;

    svg {
      height: auto;
      width: auto;
      max-width: 100px;
      fill: #fff;
    }
  }

  h1,
  h2 {
    font-size: 36px;
    font-weight: 700;
    color: $color-primary;
  }
}

@mixin form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $color-gray;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;

  .ui > & {
    height: auto;
  }

  &[type='number'] {
    padding-right: 0;
  }

  &[type='file'] {
    padding-bottom: calc(0.375rem + 30px);
  }

  &:focus {
    border-color: $color-dark;
  }
}

@mixin info-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    max-width: 600px;
    @include doc-submit-success;

    h1 {
      font-size: 48px;
    }

    h2 {
      color: $color-dark;
      font-size: 20px;
      margin-top: 30px;
    }

    a {
      @include button;
      @include button-primary;
      display: inline-block;
      text-align: center;
      font-size: 20px;
    }
  }
}

@mixin investment-profile-experience-list {
  margin: -6px 0;

  li {
    margin: 6px 0;

    .label {
      font-style: italic;
      opacity: 0.7;
    }
  }
}

@mixin label {
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 10px !important;
}

@mixin link {
  padding: 0;
  color: $color-primary;
  line-height: 18px;
  cursor: pointer;
  transition: 0s all;
  border: none;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;

  &:hover {
    color: $color-blue;
    transition: 0s all;
  }
}

@mixin small-heading {
  color: #717171;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
}

@mixin switch($width: 48px, $height: 27px, $gutter: 3px) {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: '';
      height: calc(#{$height} - #{$gutter}* 2);
      width: calc(#{$height} - #{$gutter}* 2);
      left: $gutter;
      bottom: $gutter;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: $height;

      &::before {
        border-radius: 50%;
      }
    }
  }

  input:checked + .slider {
    background-color: $color-primary;
  }

  // input:focus + .slider {
  //   box-shadow: 0 0 1px $color-primary;
  // }

  input:checked + .slider::before {
    -webkit-transform: translateX(calc(#{$height} - #{$gutter}* 2));
    -ms-transform: translateX(calc(#{$height} - #{$gutter}* 2));
    transform: translateX(calc(#{$height} - #{$gutter}* 2));
  }
}

@mixin view-link {
  color: $color-primary;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: $color-blue;
  }
}

@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin visually-shown {
  border: inherit;
  clip: inherit;
  height: inherit;
  margin: inherit;
  overflow: inherit;
  padding: inherit;
  position: inherit;
  width: inherit;
}
