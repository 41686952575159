@import '../../../../styles/_mixins.scss';

.hobart-iac {
  .initials-group {
    flex-basis: 350px;
    flex-grow: 0;
    flex-shrink: 0;

    label {
      // TODO: Use mixin
      // @include visually-shown;
      display: block;
      height: auto;
      line-height: 1.4;
      font-size: inherit;
      margin-right: 30px;
    }

    input {
      width: 100px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .date {
    display: flex;
    align-items: center;
    margin: 15px;

    .label {
      margin-right: 30px;
      border-top: none !important;
    }

    .value {
      border-bottom: 1px solid #000;
      padding: 0 10px;
    }
  }

  .schedules > ol {
    list-style: none;
    padding-left: 0;

    li {
      h3 {
        text-align: center;
      }
    }

    ul.parties .date {
      display: inline-block;
    }
  }

  ul.initials-groups {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;

      & > * {
        margin: 15px;
        display: flex;
        align-items: center;
      }
    }
  }

  .authorized-third-party {
    min-width: 150px;
    display: inline-block;
    border-bottom: 1px solid #000;
  }

  .signatures {
    .bitsy-form-group {
      max-width: 300px;
    }

    .date {
      display: block;
    }
  }
}
