@import '../../styles/mixins.scss';

.investment-experiences-form-group {
  ul {
    li {
      @include card-box-shadow;
      padding: 12px;
      margin: 12px 0;

      .for-account {
        font-weight: 700;
        margin-bottom: 12px;
      }

      .instructional {
        margin-top: -0.75rem;
        text-align: right;
      }
    }
  }
}
