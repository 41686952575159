#iac {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.4;

  p {
    padding-bottom: 0;
  }

  h1 {
    margin: 30px 0;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h2 {
    margin: 30px 0;
    font-weight: bold;
    font-size: 15px;
    font-size: 1.5rem;
  }

  h3,
  h4 {
    margin: 15px 0;
    font-size: 11px;
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h5 {
    margin: 30px 0;
    font-size: 9px;
    font-size: 0.9rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h6 {
    margin: 24px 0;
    font-size: 8px;
    font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  .risk-tolerance-box h6,
  .quiz-questions-list h6 {
    padding-bottom: 10px;
  }

  .client-info {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: center;

    .address {
      span {
        display: block;
      }
    }
  }

  // ol.terms {
  // 	list-style: decimal;
  // }

  .signature-row {
    margin-bottom: 0;

    .signature {
      .label {
        flex-basis: 100%;
        margin-top: 0;
      }
    }

    & + .title,
    & > .date {
      display: block;
      max-width: 300px;

      & > * {
        padding: 5px 15px;
      }

      & > .label {
        margin-top: 0;
      }

      & > .value {
        font-weight: bold;
        border-bottom: 1px solid #000;
      }
    }

    & + .title {
      margin-bottom: 30px;
    }
  }

  .bitsy-table {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  ul.feeSchedule {
    list-style: none;
    padding-left: 0;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 1em;

    & > li {
      display: flex;
      text-align: center;

      &.header {
        font-size: 80%;
        font-style: italic;
      }

      & > * {
        flex-basis: 50%;
        padding: 0 6px;
      }
    }
  }

  .custodian-info {
    max-width: 800px;
    margin: 0 auto;

    .row {
      display: flex;
      margin: 10px 0;

      .label {
        margin-right: 20px;
        font-weight: bold;
        flex-basis: 240px;
      }

      .value {
        flex-basis: calc(100% - 260px);

        span {
          display: block;
        }
      }
    }
  }

  .management-options {
    text-align: center;

    .initials-group {
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      input {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .articles > ol {
    list-style: none;
  }

  .ips-box {
    text-align: center;

    .value {
      border-bottom: 1px solid #000;
      display: inline-block;
    }
  }

  ul.informationTable {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;

    li {
      text-align: center;
      flex-basis: calc(33.33% - 30px);
      flex-grow: 0;
      flex-shrink: 0;
      margin: 15px;

      span {
        display: block;
      }

      .label {
        font-style: italic;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  ul.inline {
    margin: 30px 0;

    li {
      margin: 15px;
      display: flex;

      .label {
        flex-basis: 50%;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .articles {
    h5 {
      text-align: center;
    }

    h6 {
      padding: 10px 20px;
      border-top: 2px solid #000;
      border-bottom: 2px solid #000;
      text-align: center;
      display: inline-block;
      margin: 20px auto;
    }
  }

  .risk-tolerance-box {
    text-align: center;

    ul {
      text-align: left;
    }

    .investment-profile {
      .label {
        font-style: italic;
      }

      .value {
        display: block;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }

  li.fee-schedule {
    h5 {
      text-align: left;
    }
  }
}
