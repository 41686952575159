@import '../../../../styles/_mixins.scss';

.add-iac {
  background: transparent;
  max-width: 480px;
  margin: 0 auto;

  ul.react-tabs__tab-list {
    display: flex;
    justify-content: center;
  }

  .react-tabs__tab-panel {
    h2 {
      font-size: 24px;
      margin: 12px 0;
      text-align: center;
    }

    .step-contents {
      button.accordion-toggle {
        @include button-primary;
        font-size: 18px;
        letter-spacing: 1px;
        width: 100%;
        justify-content: center;
        margin: 24px 0;
      }

      h3 {
        @include small-heading;
      }

      ul.accounts_non_retirement,
      ul.accounts_retirement {
        & > *:nth-last-of-type(1) {
          flex-basis: calc(100% - 24px);
        }
      }

      ul.feeSchedule {
        li {
          display: flex;
          align-items: center;

          & > div {
            flex-grow: 0;
            flex-shrink: 0;

            &:nth-of-type(1) {
              flex-basis: 66%;
            }

            &:nth-of-type(2) {
              flex-basis: 34%;
            }
          }

          &.header {
            text-align: center;
            @include label;
          }
        }
      }

      textarea#other_information {
        min-height: 100px;
      }

      .investor-profile {
        button.accordion-toggle,
        & > div {
          max-width: 456px;
          margin: 12px auto;
        }

        ul.investment-profiles {
          li {
            @include card-box-shadow;
            padding: 12px;
          }
        }

        button.add-investment-profile-line {
          @include button-primary;
          margin: 24px 0;
          margin-left: auto;
        }
      }

      .add-quiz-question {
        margin-top: 2.5rem;
      }

      .add-quiz-example {
        opacity: 0.7;
        font-style: italic;
      }

      ul.disclosure-documents {
        display: flex;
        flex-direction: column;
        align-content: center;

        li {
          margin: 6px 0;
          text-align: center;

          a {
            display: inline-flex;
            align-items: center;
            margin: 0 auto;

            svg {
              width: 18px;
              height: 18px;
              margin-right: 12px;
              fill: #0dc4b6;
            }

            &:hover {
              svg {
                fill: $color-blue;
                transition: 0.3s all;
              }
            }
          }
        }
      }

      .disclosures-accept {
        display: flex;
        align-items: center;
        margin: 12px auto;

        input,
        label {
          width: auto;
          margin-bottom: 0 !important;
        }

        input {
          margin-right: 18px;
        }
      }

      .info-correct {
        text-align: center;
      }

      button.continue {
        @include button-primary;
        margin: 18px 0;
        margin-left: auto;
        // font-size: 18px;
      }

      // .iac-button {
      //   @include button-primary;
      //   font-size: 18px;
      //   margin-left: auto;
      //   margin-right: auto;
      //   width: 100%;
      //   justify-content: center;
      //   max-width: 240px;
      // }
    }
  }
}
