.initials-group {
  display: block;
  max-width: 175px;
  margin: 10px;

  // Insert line break before initials field
  &::before {
    content: '';
    display: block;
  }

  input {
    max-width: 100px;
    text-align: center;
    text-transform: uppercase;
  }
}
