@import '../../../styles/mixins.scss';

.row.signature-row {
  margin: 0;
  margin-bottom: 30px;

  .signature {
    display: flex;
    flex-wrap: wrap;

    canvas,
    img,
    .blank-signature {
      width: 300px;
      max-width: calc(100% - 40px);
      border-bottom: 1px solid transparent;
      min-height: 150px;
    }

    canvas {
      @include card-box-shadow;
    }

    img,
    .blank-signature {
      border-bottom: 1px solid $color-gray-alt;
    }

    .label {
      flex-basis: 100%;
    }

    .signature-buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;

      & > button {
        margin: 10px;
        padding: 0;
        border: none;

        svg {
          width: 24px;
          height: 24px;
        }

        &.signatureRetry {
          svg,
          svg g {
            fill: $color-accent !important;
          }
        }

        &.signatureConfirm {
          svg,
          svg g {
            fill: $color-primary;
          }
        }

        &:hover,
        &.disabled {
          opacity: 0.7;
        }

        &.disabled {
          pointer-events: none;

          svg,
          svg g {
            fill: $color-gray !important;
          }
        }
      }
    }
  }

  .date {
    flex-basis: 100%;

    .value {
      display: inline-block;
      border-bottom: 1px solid #000;
    }
  }
}
