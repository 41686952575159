@import '../styles/_mixins.scss';

.investment-profile-quiz {
  .form-group {
    display: flex;
    align-items: center;

    input {
      flex: 0 0 20px;
      margin-right: 10px;
    }

    label {
      margin-bottom: 0 !important;
      font-size: 14px;
    }
  }

  // Submit and result
  & > .form-group {
    display: block;

    button.calculate-button {
      @include button-primary;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
    }

    .result {
      margin-top: 1rem;
      text-align: center;

      strong {
        font-size: 20px;
      }
    }
  }
}
