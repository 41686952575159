@import '../../styles/mixins.scss';

.bitsy-form-group {
  & > label {
    small.instructional {
      display: block;
    }
  }

  & > .bitsy-input-wrapper {
    position: relative;

    & > ul.checkbox-group {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;

      & > * {
        flex: 0 0 calc(50% - 24px);
        margin: 12px;
        display: flex;
        justify-content: center;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.05);

        label {
          width: 100%;
          height: 100%;
          padding: 12px;
          cursor: pointer;

          input {
            margin-top: 12px;
            margin-bottom: 12px;
          }

          .instructional {
            margin-bottom: 6px;
          }

          .description.bitsy-form-group {
            margin-bottom: 0;
          }

          &:hover {
            @include card-box-shadow;
          }
        }
      }
    }

    & > ul.radio-group {
      label {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        input {
          flex-basis: 20px;
          flex-grow: 0;
          flex-shrink: 0;
          margin: 15px;
        }

        .description {
          flex-basis: 100%;
          padding-left: 50px;
        }
      }
    }

    & > ul.text-multiple {
      margin: -18px 0;

      & > li {
        @include card-box-shadow;
        padding: 18px 12px;
        margin: 18px 0;

        & > .bitsy-form-group:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }

    &.to-do {
      &::before {
        content: '';
        display: block;
        position: absolute;
        background: $color-warning;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        top: calc(0.375rem - 1px);
        right: calc(0.375rem - 1px);
      }
    }
  }

  &.has-copy-button {
    & > .bitsy-input-wrapper {
      display: flex;
      align-items: center;
    }

    button.copy {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;
      padding-right: 0;
      margin-left: 6px;
      max-width: 26px;
      max-height: 100%;
      background: #fff;

      svg {
        fill: $color-primary;
        width: 100%;
        height: 100%;
      }

      &:hover {
        svg {
          fill: $color-blue;
        }
      }
    }
  }
}
