@import './IACForm.scss';

.step-contents {
  button.accordion-toggle {
    @include button-primary;
    font-size: 18px;
    letter-spacing: 1px;
    width: 100%;
    justify-content: center;
    margin: 24px 0;
  }

  h3 {
    @include small-heading;
  }

  ul.accounts_non_retirement,
  ul.accounts_retirement {
    & > *:nth-last-of-type(1) {
      flex-basis: calc(100% - 24px);
    }
  }
  .disclosures-accept {
    display: flex;
    align-items: center;
    margin: 12px auto;

    input,
    label {
      width: auto;
      margin-bottom: 0 !important;
    }

    input {
      margin-right: 18px;
    }
  }
  ul.disclosure-documents {
    display: flex;
    flex-direction: column;
    align-content: center;

    li {
      margin: 6px 0;
      text-align: center;

      a {
        display: inline-flex;
        align-items: center;
        margin: 0 auto;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 12px;
          fill: #0dc4b6;
        }

        &:hover {
          svg {
            fill: $color-blue;
            transition: 0.3s all;
          }
        }
      }
    }
  }

}

.client-form form input#legal_name {
  display: none;
}
