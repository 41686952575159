form#register-firm,
form#register-advisor {
  font-size: 1.28571429rem;

  .bitsy-form-group {
    margin-bottom: 1em;
  }

  input,
  select,
  textarea {
    padding: 0.67857143em 1em;
    line-height: 1.21428571em;
    font-size: 1em;
    height: auto;
  }

  .fee-schedule .row {
    .flex-row {
      &:nth-of-type(1) {
        flex-basis: 60%;
      }

      &:nth-of-type(2) {
        flex-basis: 40%;
        text-align: center;
      }
    }

    &:not(.header) {
      font-size: 12px;
    }
  }

  // Example schedule
  div.fee-schedule {
    padding: 15px 0;
    padding-bottom: 30px;
  }

  .back-office-support-cost {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    & > * {
      flex-grow: 1;

      &:nth-of-type(1) input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-of-type(2) select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .modal.plan-selection {
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      & > * {
        margin-left: auto;
        margin-right: auto;
      }

      select {
        font-size: 2rem;
        line-height: 2.5;
        height: auto;
      }

      button {
        width: auto;
      }
    }
  }

  button#show-password {
    margin-left: auto;
    font-size: 80%;
    padding: 8px;
  }

  .submit-section button {
    width: 100%;
    display: block;
    background: #0dc4b6;
    color: #fff;

    &:hover {
      background: #09ab9f;
    }
  }
}

form#register-advisor h2 {
  text-align: center;
}
