@import '../../styles/_mixins.scss';

.disclosures-upload {
  ul.disclosure-documents {
    li,
    a,
    button {
      display: flex;
      align-items: center;
    }

    li {
      margin: 10px auto;

      a {
        text-align: left;

        svg {
          max-width: 16px;
          max-height: 16px;
          fill: $color-primary;
          margin-right: 10px;
        }

        &:hover {
          svg {
            fill: $color-blue;
          }
        }
      }

      button {
        margin-bottom: 0;
      }
    }
  }
}
