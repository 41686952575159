@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.premeeting-questionnaire {
  .slide {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .slideContents {
    width: calc(100% - 30px);
    min-height: calc(100vh - 30px);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    position: absolute;
    right: -100vw;
    top: 0;

    & > * {
      max-width: 700px;
      width: 100%;
    }

    &.active,
    &.infoPrompt {
      right: 0;
      transition: 0.3s right;
    }

    &.infoPrompt {
      top: 20vh;
      padding-bottom: 30vh;
      animation: 0.3s ease 0s 1 slideInFromRight;
    }

    &.completed {
      right: 100vw;
      transition: 0.3s right;
    }

    .question {
      text-align: left;
    }

    .form-group {
      button:not(.remove-button) {
        margin: 8px 0;
        white-space: pre-wrap;
        background: #000018;
        border-color: #000018;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 24, 0.7);

        &:hover {
          background: #09e1c0;
          border-color: #09e1c0;
          box-shadow: 0px 2px 18px 0px rgba(9, 225, 192, 0.7);
        }

        &.selected {
          background: #fc0fc0;
          border-color: #ff0fd3;
          box-shadow: 0px 2px 18px 0px rgba(252, 15, 192, 0.7);
          border: 1px solid transparent;
        }
      }

      &.personal-advisor-form-group {
        padding: 7px 30px;

        strong.personal-advisor-heading {
          font-size: 80%;
          text-transform: capitalize;
        }
      }
    }

    label.visually-shown {
      font-size: inherit;
      line-height: inherit;
    }

    .instructional-select-multiple {
      font-size: 60%;
    }
  }
}
