@import '../../../styles/_mixins.scss';

.client-form {
  h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  h3 {
    @include small-heading;
  }

  .submit-section {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    border-top: 1px solid $color-gray;
    padding-top: 15px;

    button.continue {
      @include button-primary;
      margin-top: 15px;
      margin-left: auto;
    }
  }

  &.doc-type-selector {
    h2 {
      margin-top: 15px;
    }

    button {
      display: block;
      width: 100%;
      @include small-heading;
      border-color: $color-primary;

      &:hover {
        background: $color-primary;
        color: #fff;
      }
    }
  }

  &.doc-submit-success {
    @include doc-submit-success;
  }

  .save-button {
    @include button-primary;
    font-size: 18px;
    margin: 15px auto;
    width: 100%;
    justify-content: center;
    max-width: 240px;
  }

  .signer-buttons {
    margin: 15px auto;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      position: relative;
    }

    button:not(.retry-signing-button) {
      display: flex;
      margin: 7px;
      align-items: center;
      width: 180px;
      border-color: $color-warning;
      flex-wrap: wrap;

      &:hover {
        opacity: 0.7;
      }

      &.complete {
        pointer-events: none;
        border-color: $color-primary;
      }

      svg {
        flex-grow: 0;
        flex-shrink: 0;
      }

      .signer-info {
        text-align: left;

        small {
          @include small-heading;
          font-size: 80%;
          line-height: 1;
          display: block;
          margin-top: 6px;
          margin-bottom: 1px;
        }
      }
    }

    button.retry-signing-button {
      position: absolute;
      right: 0.75rem;
      top: 7px;
      bottom: 7px;
      height: calc(100% - 14px);
    }
  }
}
