@import '../../../../styles/_mixins.scss';

.suitability-instructions {
  text-align: center;
  margin-bottom: 1rem;
}

form.suitability-form {
  h4 {
    @include small-heading;
  }

  button.icon-button {
    display: block;
    margin: 15px auto;

    svg {
      width: 20px;
      height: 20px;
      fill: $color-accent;
    }
  }
}

.modal form.suitability-form {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}
