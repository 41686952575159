@import '../../styles/_mixins.scss';

svg.to-do {
  width: auto;
  height: auto;
  max-width: 20px;
  margin: 10px 0;
  margin-right: 10px;

  &.incomplete {
    g#Core {
      fill: $color-warning;
    }
  }

  &.complete {
    g#Core {
      fill: $color-primary;
    }
  }
}
