@import '../../../../../styles/mixins.scss';

.ui.form .rfb-item {
  // padding-top: calc(2rem + 2px);
  position: relative;
}

.ui.form label {
  font-size: 14px;
}

.ui.form .badge {
  display: inline;
  padding: 0.3em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  top: auto;
  right: auto;
  width: auto;
  height: auto;
}

.ui.form .badge-secondary {
  background-color: $color-primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: calc(-2rem + 6px);
}

.ui.form .rfb-item .form-group {
  position: relative;
}

.ui.form .rfb-item .form-group .label-required {
  margin-left: 10px;
  background: transparent;
  color: $color-warning;
  padding-left: calc(0.6em + 8px);
  position: absolute;
  font-size: 0;
  top: 34px;
  right: 7px;

  &::before {
    @include badge($color-warning, '', 8px);
    top: 2px;
  }

  &:hover {
    font-size: 10px;
    transition: 0.3s width;
  }
}

.ui.form .form-control {
  font-size: 16px;
}

.ui.form label.static {
  @include small-heading;
}
