$color-primary: #0dc4b6;
$color-primary-alt: #09ab9f;
$color-accent: #f90f90;
$color-blue: #0056b3;
$color-warning: #dd4b39;
$color-success: #09914e;
$color-gray: #ced4da;
$color-gray-alt: #495057;
$color-gray-headline: #717171;
$color-dark: #00273f;
