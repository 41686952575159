@import '../../../styles/_mixins.scss';

.profile {
  & > .row {
    justify-content: center;

    & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .card {
        flex-grow: 1;

        & > .card-body {
          .bitsy-table {
            .header {
              @include small-heading;
            }
          }
        }
      }
    }
  }

  .statistic-counter {
    font-size: 30px;
    line-height: 36px;
    font-weight: normal;
  }

  .executed-contracts-module > h3 {
    display: none;
  }
}
