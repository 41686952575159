// /* FONTS IMPORT */

// @import url('https://fonts.googleapis.com/css?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

// /* MIXINS */

// @import './styles/_mixins.scss';

// /* GENERAL */

// html {
//   @include html;
// }

// body {
//   @include global;
// }

// .register-page, .login-page {
//   @include register-login;

//   .register-toolbar, .login-toolbar {
//     background-image: linear-gradient(77deg,rgba(0,0,18,0.93) 12%,#003470 100%),url(./assets/images/stock.jpg);
//   }
// }

/* MIXINS */
@import './styles/_mixins.scss';

/* GLOBAL */
@import './styles/utils.scss';
@import './styles/document.scss';

html,
body,
.wrapper {
  min-height: 100vh;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

p {
  margin: 15px 0;
}

.visually-hidden {
  @include visually-hidden;
}

.visually-shown {
  @include visually-shown;
}

button {
  @include button;

  &[type='submit'] {
    @include button-primary;
    margin-left: auto;
  }

  &.remove-button {
    padding: 0;
    display: block;
    margin: 12px 0;
    margin-top: 0;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
      fill: $color-warning;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

a,
button.link {
  @include link;
}

input,
select,
textarea,
.ui.dropdown {
  @include form-control;
}

.switch {
  @include switch;
}

textarea {
  min-height: 60px;
}

label {
  @include label;
}

.instructional {
  opacity: 0.7;
  font-style: italic;
}

form {
  h4 {
    @include small-heading;
    margin-top: 30px;
  }
}

form.upload-form {
  .current {
    margin-bottom: 24px;
    text-align: center;
  }

  .new {
    button {
      @include button-primary;
      margin-left: auto;
      margin-top: 6px;
    }
  }
}

ul.investment-profiles,
ul.investment-experience {
  @include investment-profile-experience-list;
}

.badge {
  @include badge(#0dc4b6, '', 24px);
}

.react-tabs {
  ul.react-tabs__tab-list {
    border-bottom: 0;
    display: flex;
    margin-bottom: 30px;

    li.react-tabs__tab {
      flex-grow: 1;
      border: none;
      bottom: 0;
      border-radius: 0;
      background: $color-gray;
      text-align: center;
      color: $color-gray-alt;
      font-weight: 500;
      margin-left: 1px;
      opacity: 0.6;

      &:nth-of-type(1) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        margin-left: 0;
      }

      &:nth-last-of-type(1) {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &.react-tabs__tab--selected {
        opacity: 1;
        background: $color-primary;
        color: #fff;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

ol {
  list-style: decimal;

  &.number {
    list-style: decimal;
  }

  &.upper-roman {
    list-style: upper-roman;
    padding-left: 70px;
  }

  &.lower-alpha-parens {
    counter-reset: list;
    padding-left: 1.4em;
  }

  &.lower-alpha-parens > li {
    list-style: none;
    position: relative;
    padding-left: 10px;

    &::before {
      content: '(' counter(list, lower-alpha) ') ';
      counter-increment: list;
      position: absolute;
      left: -1.4em;
    }
  }

  li {
    & > ol {
      margin-left: 1.4em;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.red {
  color: red;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.esign-page,
.checkout-redirect {
  @include info-page;
}

.banner {
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  padding-left: 1rem;

  &.banner-success {
    background: $color-success;
    color: #fff;
  }

  &.banner-warning {
    background: $color-warning;
    color: #fff;
  }
}

.text-icon {
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  font-size: 150%;
  color: $color-primary;
  cursor: help;
}
