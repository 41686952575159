div#suitability-form {
  // font-size: 11px;
  // font-size: 1.1rem;
  line-height: 1.4;

  p {
    padding-bottom: 0;
  }

  h1 {
    margin: 30px 0;
    // font-size: 20px;
    // font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h2 {
    margin: 30px 0;
    font-weight: bold;
    // font-size: 15px;
    // font-size: 1.5rem;
  }

  h3,
  h4 {
    margin: 15px 0;
    // font-size: 11px;
    // font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h5 {
    margin: 30px 0;
    // font-size: 9px;
    // font-size: 0.9rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  h6 {
    margin: 24px 0;
    // font-size: 8px;
    // font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 0;
  }

  .masthead,
  h1 {
    text-align: center;
  }

  .risk-tolerance-box h6,
  .quiz-questions-list h6 {
    padding-bottom: 10px;
  }

  .table {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
    justify-content: center;

    .column {
      flex-basis: calc(33.33% - 30px);
      margin: 15px;

      .row {
        display: block;
        text-align: center;

        &.header-row {
          h2 {
            margin-top: 0;
            margin-bottom: 15px;
          }
        }
      }
    }

    ul.informationTable {
      display: flex;
      justify-content: center;
      list-style: none;
      padding-left: 0;

      li {
        margin: 15px;

        .label {
          font-style: italic;
          margin-bottom: 10px;
        }
      }
    }

    &.tax-assets {
      // Assets Held Away
      .column:nth-of-type(2) {
        flex-grow: 1;
      }
    }
  }

  .client-info {
    font-size: 14px;
    font-size: 1.4rem;
    display: flex;
    margin: 60px 0;

    .investor-info {
      flex-basis: 50%;

      .row {
        display: flex;

        .label {
          flex-basis: 33.33%;
        }

        .value {
          font-weight: bold;
        }
      }
    }
  }

  // ol.terms {
  // 	list-style: decimal;
  // }
}
