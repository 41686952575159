.centennial-iac {
  .initials-group {
    max-width: 100px;
    margin: 10px;
    display: inline-block;
  }

  ul.investment-profiles {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      margin: 4px 0;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    .label {
      font-style: italic;
      opacity: 0.7;
    }
  }

  h5 {
    text-align: center;
  }

  ol.lower-alpha-parens > li {
    display: flex;

    & > * {
      display: block;
    }

    .initials-group {
      display: block;
      margin-top: 0;
    }
  }

  .update-date {
    text-align: center;
    margin: 30px 0;
  }
}
