@import '../../styles/_mixins.scss';

ul.bitsy-errors {
  margin: 24px 0;
  padding: 6px 0;
  background: #f56565;
  color: #fff;

  li {
    padding: 0.5rem 1rem;
    padding-left: 1rem;
  }

  &.sticky-errors {
    position: fixed;
    top: 10px;
    right: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fff5f5;
    border-radius: 0.5rem;
    margin: 0;
    padding: 0;
    border: 1px solid #fc8181;
    max-width: 360px;
    max-height: calc(100vh - 20px);
    z-index: 9999999;

    li {
      color: $color-warning;
      list-style: disc;
      text-align: left;

      &.title {
        background: #f56565;
        list-style: none;
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 0;
      }

      &:nth-of-type(1) {
        padding-right: 36px;
      }
    }

    button.button-close {
      background: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px;
      line-height: 1;
      width: auto;
      z-index: 999999;

      svg {
        width: 14px;
        height: 14px;
        fill: #fff;
      }

      &:hover {
        opacity: 0.7;
        transition: 0.3s opacity;
      }
    }
  }
}
